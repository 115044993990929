<ng-container *transloco="let t; prefix: 'FOOTER'">

  <div class="row mx-0 justify-content-center align-items-center text-white bg-primary g-1 small" style="height: 50px">
    <div class="col-auto">
      <!--    © 2024 REM-->
      © 2024 ZERTIFIER
    </div>
    <div class="col-auto">|</div>
    <div class="col-auto">
      <a [routerLink]="['/privacy-policy']" class="text-white">{{ t('texts.privacy') }}</a>
    </div>
    <div class="col-auto">|</div>
    <div class="col-auto">
      <a [routerLink]="['/cookies-policy']" class="text-white">{{  t('texts.cookies') }}</a>
    </div>
    <div class="col-auto">|</div>
    <div class="col-auto">
      <a [routerLink]="['/legal-notice']" class="text-white">{{  t('texts.legal') }}</a>
    </div>
    <div class="col-auto">|</div>
    <div class="col-auto">
      <app-language></app-language>
    </div>
  </div>
</ng-container>
